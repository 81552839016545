import { PricingLists } from "@components-v3/PricingLists";
import { Query } from "@graphql-types";
import { Container } from "@util/standard";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { GlobalStyle } from "../styles/globalStyles";
import { createGlobalStyle } from "styled-components";

export const query = graphql`
  {
    allSanityFeaturesPage {
      nodes {
        pricingLists {
          _key
          _type
          title
          description
          costDescription
          currency
          billingInterval
          unit
          featureList
          pricePerBillingInterval
          perUnitSuffix
          annualPrice
          annualPriceBillingInterval
          listHeader
          pricingButton {
            isInternal
            linkText
            linkUrl
            elementId
            isMobileInternal
            mobileLinkText
            mobileLinkURL
          }
        }
      }
    }
  }
`;

const PlanEmbed = () => {
  const data: Query = useStaticQuery(query);

  const pricingLists = data.allSanityFeaturesPage.nodes[0].pricingLists;


  return (
    <>
      <GlobalStyle />
      <BodyStyle />
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          placeItems: "center",
        }}
        margin="auto"
      >
        <h2 style={{ textAlign: "center" }}>Celo Plans</h2>
        <PricingLists sanityPricingLists={pricingLists} isPrimary/>
      </Container>
    </>
  );
};

const BodyStyle = createGlobalStyle`
body {
  background-color: transparent !important;
}
`;

export default PlanEmbed;
